import TipoNotificacao from "enum/TipoNotificacao.enum";

const user = (DataBase, Datatype) => {
    const User = DataBase.new({
        name: Datatype.STRING,
        contract_count: Datatype.NUMBER,
        print_by_default: Datatype.BOOLEAN,
        contract_logo_url: Datatype.STRING,
        contract_footer_url: Datatype.STRING,
        atv_notification: Datatype.BOOLEAN,
        notification: Datatype.ARRAY,
        ntf_antecipacao_carregamento: Datatype.NUMBER,
        ntf_antecipacao_comissao: Datatype.NUMBER,
        ntf_antecipacao_agendamento: Datatype.NUMBER,
    }, {
        auth: false,
        dateLogs: true,
        softDelete: true,
        collection: 'user',
        model: 'User',
    });

    User.defaults = {
        contract_count: 0,
        print_by_default: false, 
        atv_notification: true,
        notification: TipoNotificacao,
        ntf_antecipacao_carregamento: 0,
        ntf_antecipacao_comissao: 0,
        ntf_antecipacao_agendamento: 0,
    }

    return User;
}

export default user;