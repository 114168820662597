import dayjs from "dayjs";
import { setCache } from "store/slices/cacheSlice";
import { where } from "auth/FirebaseAuth";
import FirebaseService from "services/FirebaseService";
import FirebaseDatatypeEnum from "enum/FirebaseDatatype.enum"
import TipoWhere from "enum/TipoWhere.enum";
import store from "store";
import Utils from "utils";

// Models import
import User from "./user";
import Empresa from "./empresa";
import Produto from "./produto";
import Prazo from "./prazo";
import Pedido from "./pedido";
import Agenda from "./agenda";
import Marcador from "./marcador";
import Comissao from "./comissao";
import NotificacaoVisualizada from "./notificacao-visualizada";

const userID =  () => store.getState().user.id;
const getCache = () => store.getState().cache;

const typesFormatters = {
    [FirebaseDatatypeEnum.STRING]: value => String(value),
    [FirebaseDatatypeEnum.NUMBER]: value => Number(value),
    [FirebaseDatatypeEnum.BOOLEAN]: value => Boolean(value),
    [FirebaseDatatypeEnum.ARRAY]: value => Array.isArray(value) ? value : [],
    [FirebaseDatatypeEnum.NULL]: () => null,
    [FirebaseDatatypeEnum.TIMESTAMP]: value => dayjs(value).format(),
    [FirebaseDatatypeEnum.CUSTOM]: value => value,
}

const whereFunctions = {
    [TipoWhere.EQUAL]: (_, key, value) => (row) => row[key] === value,
    [TipoWhere.BETWEEN]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.TIMESTAMP,
        ].includes(type)){
            return (row) => {
                const val = dayjs(row[key]);
                return val.isAfter(value[0]) && val.isBefore(value[1]);
            }
        } else {
            throw new Error("Tipo inpróprio para o comparador 'BETWEEN'");
        }
    },
    [TipoWhere.LIKE]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.STRING,
        ].includes(type)){
            return (row) => Utils.compareStrings(row[key], value)
        }else{
            throw new Error("Tipo inpróprio para o comparador 'LIKE'")
        }
    },
    [TipoWhere.ILIKE]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.STRING,
            FirebaseDatatypeEnum.NUMBER,
            FirebaseDatatypeEnum.TIMESTAMP,
        ].includes(type)){
            return (row) => Utils.searchString(row[key], value);
        }else{
            throw new Error("Tipo inpróprio para o comparador 'ILIKE'")
        }
    },
    [TipoWhere.GREATER]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.STRING,
            FirebaseDatatypeEnum.NUMBER,
            FirebaseDatatypeEnum.TIMESTAMP,
        ].includes(type)){
            const types = {
                [FirebaseDatatypeEnum.STRING]: (row) => row[key].length > value.length,
                [FirebaseDatatypeEnum.NUMBER]: (row) => row[key] > value,
                [FirebaseDatatypeEnum.TIMESTAMP]: (row) => dayjs(row[key]).isAfter(dayjs(value)),
            }
            return types[type];
        }else{
            throw new Error("Tipo inpróprio para o comparador 'GREATER'")
        }
    },
    [TipoWhere.GREATER_EQUAL]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.STRING,
            FirebaseDatatypeEnum.NUMBER,
            FirebaseDatatypeEnum.TIMESTAMP,
        ].includes(type)){
            const types = {
                [FirebaseDatatypeEnum.STRING]: (row) => row[key].length >= value.length,
                [FirebaseDatatypeEnum.NUMBER]: (row) => row[key] >= value,
                [FirebaseDatatypeEnum.TIMESTAMP]: (row) => {
                    const comp_val = dayjs(row[key]);
                    const val = dayjs(value)
                    return comp_val.isAfter(val) || comp_val.isSame(val)
                },
            }
            return types[type];
        }else{
            throw new Error("Tipo inpróprio para o comparador 'GREATER_EQUAL'")
        }
    },
    [TipoWhere.LOWER]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.STRING,
            FirebaseDatatypeEnum.NUMBER,
            FirebaseDatatypeEnum.TIMESTAMP,
        ].includes(type)){
            const types = {
                [FirebaseDatatypeEnum.STRING]: (row) => row[key].length < value.length,
                [FirebaseDatatypeEnum.NUMBER]: (row) => row[key] < value,
                [FirebaseDatatypeEnum.TIMESTAMP]: (row) => dayjs(row[key]).isBefore(dayjs(value)),
            }
            return types[type];
        }else{
            throw new Error("Tipo inpróprio para o comparador 'LOWER'")
        }
    },
    [TipoWhere.LOWER_EQUAL]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.STRING,
            FirebaseDatatypeEnum.NUMBER,
            FirebaseDatatypeEnum.TIMESTAMP,
        ].includes(type)){
            const types = {
                [FirebaseDatatypeEnum.STRING]: (row) => row[key].length <= value.length,
                [FirebaseDatatypeEnum.NUMBER]: (row) => row[key] <= value,
                [FirebaseDatatypeEnum.TIMESTAMP]: (row) => {
                    const comp_val = dayjs(row[key]);
                    const val = dayjs(value)
                    return comp_val.isBefore(val) || comp_val.isSame(val)
                },
            }
            return types[type];
        }else{
            throw new Error("Tipo inpróprio para o comparador 'LOWER_EQUAL'")
        }
    },
    [TipoWhere.ARRAY_CONTAIN]: (type, key, value) => {
        if([
            FirebaseDatatypeEnum.ARRAY
        ].includes(type)){
            return (row) => row[key].includes(value);
        }else{
            throw new Error("Tipo inpróprio para o comparador 'ARRAY_CONTAIN'")
        }
    },
    [TipoWhere.IN]: (_, key, value) => (row) => value.includes(row[key]),
}

const dataFormatter = (model, defaults, data, ignoreUndefs = false) => {
    if(data){
        if(Array.isArray(data)){
            return data.map(row => Object.keys(model).reduce((acc, key) => {
                if(row[key] !== undefined && row[key] !== null){
                    acc[key] = typesFormatters[model[key]](row[key]);
                }else if(!ignoreUndefs){
                    acc[key] = defaults[key] ?? null
                }
        
                return acc;
            }, {}))
        }else{
            return Object.keys(model).reduce((acc, key) => {
                if(data[key] !== undefined && data[key] !== null){
                    acc[key] = typesFormatters[model[key]](data[key]);
                }else if(!ignoreUndefs){
                    acc[key] = defaults[key] ?? null
                }
        
                return acc;
            }, {})
        }
    }
} 

const paramsConstructor = (model, params, relations) => {
    const newParams = {
        where: Object.keys(params.where).filter(key => "deletedAt" === key).map(key => where(key, params.where[key].op, params.where[key].value)),
        join: params.join?.map(ass => relations.find(rel => rel.name === ass)),
        where_after: Object.keys(params.where).filter(key => "deletedAt" !== key).map(key => whereFunctions[params.where[key].op](model[key], key, params.where[key].value)),
    }

    return newParams;
}

class Model {
    #defaults = {};
    #keys;
    #configs;
    #relations;
    #relate;

    constructor ({ keys, configs }) {
        this.#keys = keys;
        this.#configs = configs;
    }

    get ModelName () {
        return this.#configs.model;
    }

    /**
     * @param {Function} callback
     */
    set relate (callback) {
        this.#relate = callback
    }

    /**
     * @param {Object} obj
     */
    set defaults (obj) {
        this.#defaults = obj
    }

    doRelate (models) {
        if(this.#relate){
            this.#relations = this.#relate(models);
        }
    };

    async findAll (params = {}) {
        params.where = params.where ?? {}
        params.join = params.join ?? []

        if(this.#configs.softDelete){
            params.where.deletedAt = {
                op: "==",
                value: null
            }
        }
        
        const newParams = paramsConstructor(this.#keys, params, this.#relations);

        let firstData = [];

        if(getCache()[this.#configs.model]){
            firstData = getCache()[this.#configs.model]
        } else {
            let path = `${this.#configs.collection}/`;
    
            if(this.#configs.auth){
                path = `user/${userID()}/${path}`
            }
    
            firstData = await FirebaseService.getCollection(path, newParams.where);

            store.dispatch(setCache({
                [this.#configs.model]: firstData
            }));
        }

        firstData = firstData.filter(row => newParams.where_after.reduce((acc, func) => {
            if(acc){
                acc = func(row);
            }

            return acc;
        }, true))

        const newModel = {...this.#keys};

        if(params.join?.length > 0){

            const joinModels = newParams.join.reduce((acc, row) => {
                newModel[row.name] = FirebaseDatatypeEnum.CUSTOM;
                if(!acc[row.model.ModelName]){
                    acc[row.model.ModelName] = row.model
                }
                return acc;
            }, {})

            const joinQuery = {};

            await Promise.all(Object.keys(joinModels).map(key => {
                const getJoinData = async () => {
                    joinQuery[key] = await joinModels[key].findAll()
                }

                return getJoinData()
            }))

            firstData = firstData.map(row => {
                const newRow = {...row}
                newParams.join.forEach(ass => {
                    newRow[ass.name] = joinQuery[ass.model.ModelName].find(joinRow => joinRow.id === row[ass.fk])
                })

                return newRow
            })
        }

        return dataFormatter(newModel, this.#defaults, firstData, true)
    }

    async findOne (params = {}) {
        params.where = params.where ?? {};
        params.join = params.join ?? [];

        let path = `${this.#configs.collection}/`;

        if(this.#configs.auth){
            path = `user/${userID()}/${path}`
        }

        if(this.#configs.softDelete){
            params.where.deletedAt = {
                op: "==",
                value: null
            }
        }

        const newParams = paramsConstructor(this.#keys, params, this.#relations);
        
        const data = (await FirebaseService.getCollection(path, newParams.where) ?? []).filter(row => newParams.where_after.reduce((acc, func) => {
            if(acc){
                acc = func(row);
            }
            return acc;
        }, true)) ;

        return dataFormatter(this.#keys, {}, data[0], true)
    }

    async findByID (id) {
        let path = `${this.#configs.collection}/`;
        if(this.#configs.auth){
            path = `user/${userID()}/${path}`
        }
        const data = await FirebaseService.getDoc(path, id);
        data.id = id;

        if(data.deletedAt === null || !this.#configs.softDelete){
            return dataFormatter(this.#keys, {}, data, true);
        }
    }

    async create (data, id) {
        let path = `${this.#configs.collection}/`;

        const newModel = {...this.#keys};

        if(this.#configs.auth){
            path = `user/${userID()}/${path}`
        }

        if(this.#configs.dateLogs && !data.createdAt) {
            data.createdAt = new Date()
        }

        delete newModel.id;
        
        const newRow = await FirebaseService.createData(path, dataFormatter(newModel, this.#defaults, data), id);

        data.id = newRow.id;

        if(getCache()[this.#configs.model]){
            store.dispatch(setCache({
                [this.#configs.model]: [
                    ...getCache()[this.#configs.model], 
                    data
                ],
            }));
        } 

        return newRow;
    }

    async update (id, data) {
        let path = `${this.#configs.collection}/`;

        if(this.#configs.auth){
            path = `user/${userID()}/${path}`
        }

        if(this.#configs.dateLogs && !data.updatedAt) {
            data.updatedAt = new Date()
        }

        if(getCache()[this.#configs.model]){
            store.dispatch(setCache({
                [this.#configs.model]: [
                    ...getCache()[this.#configs.model].map(row => {
                        if(row.id === id){
                            row = {...row, ...data};
                        }

                        return row;
                    })
                ],
            }));
        } 

        return FirebaseService.updateData(path, id, dataFormatter(this.#keys, {}, data, true))
    }

    async delete (id) {
        let path = `${this.#configs.collection}/`;

        if(this.#configs.auth){
            path = `user/${userID()}/${path}`
        }

        store.dispatch(setCache({
            [this.#configs.model]: getCache()[this.#configs.model].filter(row => row.id !== id)
        }));

        if(this.#configs.softDelete){
            return await FirebaseService.updateData(path, id, dataFormatter(this.#keys, {}, { deletedAt: new Date() }, true))
        }else{
            return await FirebaseService.deleteData(path, id);
        }
    }
}

const initFunction = {
    new: (model, configs) => {
        
        model.id = FirebaseDatatypeEnum.STRING;
        model.createdAt = FirebaseDatatypeEnum.TIMESTAMP;
        model.updatedAt = FirebaseDatatypeEnum.TIMESTAMP;
        model.deletedAt = FirebaseDatatypeEnum.TIMESTAMP;

        return new Model({keys: model, configs})
    }
}

const models = [
    User,
    Empresa,
    Produto,
    Prazo,
    Pedido,
    Agenda,
    Marcador,
    Comissao,
    NotificacaoVisualizada,
].reduce((acc, model) => {
    const newModel = model(initFunction, FirebaseDatatypeEnum);

    acc[newModel.ModelName] = newModel;

    return acc;
}, {})

Object.keys(models).forEach(key => {
    models[key].doRelate(models);
})

export default models;