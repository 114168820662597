const notificacaoVisualizada = (DataBase, Datatype) => {
    const NotificacaoVisualizada = DataBase.new({
        collection_id: Datatype.ARRAY,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'notificacao_visualizada',
        model: 'NotificacaoVisualizada',
    });

    return NotificacaoVisualizada;
}

export default notificacaoVisualizada;