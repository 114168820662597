import { createSlice } from '@reduxjs/toolkit'

export const initialState = {}

export const cacheSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
        setCache: (state, action) => {
            Object.keys(action.payload).forEach(key => {
                state[key] = action.payload[key]
            })
        },
        cleanCache: (state) => {
            const stateKeys = [...Object.keys(state)]
            stateKeys.forEach(key => {
                delete state[key]
            })
        }
	},
})

export const { 
    setCache,
    cleanCache
} = cacheSlice.actions

export default cacheSlice.reducer