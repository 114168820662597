import { 
  auth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  db,
  doc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  query,
  updateDoc,
  deleteDoc,
} from 'auth/FirebaseAuth';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => 
  await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);

FirebaseService.signOutRequest = async () =>
	await signOut(auth).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
	await createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);	

FirebaseService.getDoc = async (path, id) => {
  const docRef = doc(db, path, id)
  return (await getDoc(docRef)).data()
}

FirebaseService.getCollection = async (path, queryParams) => {
  const colRef = collection(db, path)
  let snapshot;

  if(queryParams){
    const queryRef = query(colRef, ...queryParams)
    snapshot = (await getDocs(queryRef)).docs;
  }else{
    snapshot = (await getDocs(colRef)).docs;
  }

  const list = snapshot.map(doc => {
    return {id: doc.id, ...doc.data()}
  });
  return list ?? []
}

FirebaseService.createData = async (path, data, id) => {
  let docRef;

  if(id){
    docRef = doc(db, path, id);
  }else{
    docRef = doc(collection(db, path));
  }
  
  await setDoc(docRef, data);

  return {
    id: id || docRef._key.path.segments[docRef._key.path.segments.length - 1]
  }
}

FirebaseService.updateData = async(path, id, data) => updateDoc(doc(db, path, id), data)

FirebaseService.deleteData = async (path, id) => deleteDoc(doc(db, path, id))

export default FirebaseService