import 'dayjs/locale/pt-br'
import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App';
import 'antd/dist/reset.css'
import dayjs from 'dayjs';

dayjs.locale('pt-br')

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);
