const produto = (DataBase, Datatype) => {
    const Produto = DataBase.new({
        nome: Datatype.STRING,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'produto',
        model: 'Produto',
    });

    return Produto;
}

export default produto;