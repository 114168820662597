import store from 'store';
import FirebaseService from './FirebaseService'
import models from 'models';
import { onUserAuthenticated, onUserLogOut, onUserRegister } from 'store/slices/userSlice';
import { cleanCache } from 'store/slices/cacheSlice';
import TipoNotificacao from 'enum/TipoNotificacao.enum';

const AuthService = {}

AuthService.login = async (data) => {
	return FirebaseService.signInEmailRequest(...Object.values(data));
}

AuthService.register = async (data) => {
	store.dispatch(onUserRegister())
	const response = await FirebaseService.signUpEmailRequest(...Object.values(data));
	
	if(response.user){
		store.dispatch(onUserAuthenticated({
			id: response.user.uid,
			name: data.name,
			data: data.email,
			print_by_default: false,
			atv_notification: true,
			notification: TipoNotificacao,
		}))
		await models.User.create({name: data.name}, response.user.uid)
	}

	return response;
}

AuthService.logout = async () => {
	store.dispatch(cleanCache())
	store.dispatch(onUserLogOut())
	return FirebaseService.signOutRequest()
}

export default AuthService;