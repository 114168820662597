const pedido = (DataBase, Datatype) => {
    const Pedido = DataBase.new({
        id_vendedor: Datatype.STRING,
        id_comprador: Datatype.STRING,
        id_transportador: Datatype.STRING,
        id_produto: Datatype.STRING,
        id_prazo: Datatype.STRING,
        id_pagador_comissao: Datatype.STRING,
        nr_pedido: Datatype.NUMBER,
        quantidade: Datatype.NUMBER,
        preco: Datatype.NUMBER,
        icms: Datatype.NUMBER,
        tipo_transporte: Datatype.NUMBER,
        data: Datatype.TIMESTAMP,
        tipo_comissao: Datatype.NUMBER,
        comissao: Datatype.NUMBER,
        obs: Datatype.STRING,
        nr_nota: Datatype.NUMBER,
        data_nota: Datatype.TIMESTAMP,
        peso_nota: Datatype.NUMBER,
        valor_nota: Datatype.NUMBER,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'pedido',
        model: 'Pedido',
    });

    Pedido.relate = (models) => [{
        model: models.Empresa,
        fk: "id_vendedor",
        name: "vendedor",
    }, {
        model: models.Empresa,
        fk: "id_comprador",
        name: "comprador",
    }];

    return Pedido;
}

export default pedido;