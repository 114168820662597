const TipoWhere = {
    EQUAL: 0,
    BETWEEN: 1,
    LIKE: 2,
    ILIKE: 3,
    GREATER: 4,
    GREATER_EQUAL: 5,
    LOWER: 6,
    LOWER_EQUAL: 7,
    ARRAY_CONTAIN: 8,
    IN: 9,
};

export default TipoWhere;