const prazo = (DataBase, Datatype) => {
    const Prazo = DataBase.new({
        descricao: Datatype.STRING,
        intervalos: Datatype.ARRAY,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'prazo',
        model: 'Prazo',
    });

    return Prazo;
}

export default prazo;