import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    }, {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    }, {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboard')),
    }, {
        key: 'agenda',
        path: `${APP_PREFIX_PATH}/agenda`,
        component: React.lazy(() => import('views/app-views/agenda')),
    }, 
    {
        key: 'lista-pedido',
        path: `${APP_PREFIX_PATH}/lista-pedido`,
        component: React.lazy(() => import('views/app-views/lista-pedido')),
    }, {
        key: 'copiar-pedido',
        path: `${APP_PREFIX_PATH}/copiar-pedido/:id_copia`,
        component: React.lazy(() => import('views/app-views/pedido')),
    }, {
        key: 'editar-pedido',
        path: `${APP_PREFIX_PATH}/pedido/:id`,
        component: React.lazy(() => import('views/app-views/pedido')),
    }, {
        key: 'criar-pedido',
        path: `${APP_PREFIX_PATH}/pedido`,
        component: React.lazy(() => import('views/app-views/pedido')),
    }, {
        key: 'lista-empresa',
        path: `${APP_PREFIX_PATH}/cadastro/lista-empresa`,
        component: React.lazy(() => import('views/app-views/cadastro/lista-empresa')),
    }, {
        key: 'editar-empresa',
        path: `${APP_PREFIX_PATH}/cadastro/empresa/:id`,
        component: React.lazy(() => import('views/app-views/cadastro/empresa')),
    }, {
        key: 'criar-empresa',
        path: `${APP_PREFIX_PATH}/cadastro/empresa`,
        component: React.lazy(() => import('views/app-views/cadastro/empresa')),
    }, {
        key: 'lista-produto',
        path: `${APP_PREFIX_PATH}/cadastro/lista-produto`,
        component: React.lazy(() => import('views/app-views/cadastro/lista-produto')),
    }, {
        key: 'editar-produto',
        path: `${APP_PREFIX_PATH}/cadastro/produto/:id`,
        component: React.lazy(() => import('views/app-views/cadastro/produto')),
    }, {
        key: 'criar-produto',
        path: `${APP_PREFIX_PATH}/cadastro/produto`,
        component: React.lazy(() => import('views/app-views/cadastro/produto')),
    }, {
        key: 'lista-prazo',
        path: `${APP_PREFIX_PATH}/cadastro/lista-prazo`,
        component: React.lazy(() => import('views/app-views/cadastro/lista-prazo')),
    }, {
        key: 'editar-prazo',
        path: `${APP_PREFIX_PATH}/cadastro/prazo/:id`,
        component: React.lazy(() => import('views/app-views/cadastro/prazo')),
    }, {
        key: 'criar-prazo',
        path: `${APP_PREFIX_PATH}/cadastro/prazo`,
        component: React.lazy(() => import('views/app-views/cadastro/prazo')),
    }, {
        key: 'configuracoes',
        path: `${APP_PREFIX_PATH}/configuracoes`,
        component: React.lazy(() => import('views/app-views/configuracoes')),
    }
]