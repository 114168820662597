const marcador = (DataBase, Datatype) => {
    const Marcador = DataBase.new({
        descricao: Datatype.STRING,
        cor: Datatype.STRING,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'marcador',
        model: 'Marcador',
    });

    return Marcador;
}

export default marcador;