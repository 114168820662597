import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: null,
    name: 'Usuário',
    email: 'example@email.com',
    first_login: false,
    contract_logo_url: '',
    contract_footer_url: '',
    print_by_default: false,
    atv_notification: false,
    notification: [],
    ntf_antecipacao_carregamento: 0,
    ntf_antecipacao_comissao: 0,
    ntf_antecipacao_agendamento: 0,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
        onUserRegister: (state) => {
            state.first_login = true;
        },
        onUserAuthenticated: (state, action) => {
            if(state.first_login){
                state.first_login = false;
            }

            state.id = action.payload.id ?? state.id;
            state.name = action.payload.name ?? state.name;
            state.email = action.payload.email ?? state.email;
            state.contract_logo_url = action.payload.contract_logo_url ?? state.contract_logo_url;
            state.contract_footer_url = action.payload.contract_footer_url ?? state.contract_footer_url;
            state.print_by_default = action.payload.print_by_default ?? state.print_by_default;
            state.atv_notification = action.payload.atv_notification ?? state.atv_notification;
            state.notification = action.payload.notification ?? state.notification;
            state.ntf_antecipacao_carregamento = action.payload.ntf_antecipacao_carregamento ?? state.ntf_antecipacao_carregamento;
            state.ntf_antecipacao_comissao = action.payload.ntf_antecipacao_comissao ?? state.ntf_antecipacao_comissao;
            state.ntf_antecipacao_agendamento = action.payload.ntf_antecipacao_agendamento ?? state.ntf_antecipacao_agendamento;
        },
        onUserLogOut: (state) => {
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key];
            })
        },
	},
})

export const { 
    onUserRegister,
    onUserAuthenticated,
    onUserLogOut,
} = userSlice.actions

export default userSlice.reducer