import { initializeApp } from "firebase/app"
import { getFirestore, collection, getDocs, getDoc, setDoc, doc, where, query, updateDoc, deleteDoc } from 'firebase/firestore/lite';
import { 
	getAuth, 
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
} from 'firebase/auth'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { onUserAuthenticated } from 'store/slices/userSlice';
import store from '../store';

import firebaseConfig from 'configs/FirebaseConfig';
import models from "models";

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser

auth.onAuthStateChanged(async (res) => {
	if(res && !store.getState().user.first_login){
		const userData = await models.User.findByID(res.uid)
		store.dispatch(
			onUserAuthenticated(userData)
		)
	}
})

export {
	db,
	auth,
	collection,
	getDocs,
	getDoc,
	setDoc,
	updateDoc,
	deleteDoc,
	doc,
	where,
	query,
	currentUser,
	signInWithEmailAndPassword,
	signOut,
	createUserWithEmailAndPassword
};