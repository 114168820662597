import { Button, Input, DatePicker, Flex } from 'antd';
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useRef } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

const FilterWrapper = styled("div")`
  padding: 8px;
`

const SearchFactory = (column, Icon, filterProps) => {
  const searchText = useRef('');
  let searchInput = null;

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    searchText.current = selectedKeys[0]
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    searchText.current = ''
    confirm();
  };
  
  return {
    align: column.align,
    dataIndex: column.dataIndex,
    title: column.title,
    key: column.key,
    defaultSortOrder: column.defaultSortOrder,
    sorter: column.sorter,
    filterDropdownProps: {
      onOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput?.select(), 100);
        }
      }
    },
    filterIcon: filtered => <Icon style={{ color: filtered ? '#455560' : '#ececec' }} />,
    ...filterProps(handleSearch, handleReset, (node)=>{searchInput = node}, searchText),
    ...column.render ? { render: column.render } : {},
  }
}

const ColumnSearch = {
  Input: column => SearchFactory(column, SearchOutlined, (handleSearch, handleReset, searchInput, searchText) => ({
      onFilter: (value, record) => {
        if(Array.isArray(column.dataIndex)){
          const recordValue = column.dataIndex.reduce((acc, key) => {
            return acc[key]
          }, record)
          return recordValue?.toString().toLowerCase().includes(value.toLowerCase())
        }else{
          return record[column.dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <FilterWrapper>
          <Input
            ref={node => {
              searchInput(node);
            }}
            placeholder={`Buscar ${column.placeholder}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, setSelectedKeys)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Flex
            justify='space-between'
          >
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Limpar
            </Button>
          </Flex>
        </FilterWrapper>
      ),
      render: text => <Highlighter 
        autoEscape
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText.current]}
        textToHighlight={text ? text.toString() : ''}
      />,
  })),
  DatePicker: column => SearchFactory(column, CalendarOutlined, (handleSearch) => ({
    onFilter: (value, record) => {
      if(Array.isArray(column.dataIndex)){
        const recordValue = column.dataIndex.reduce((acc, key) => {
          return acc[key]
        }, record)
        return dayjs(recordValue).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
      }else{
        return dayjs(record[column.dataIndex]).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
      }
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <FilterWrapper>
        <DatePicker 
          format="DD/MM/YYYY"
          onChange={value => {setSelectedKeys(value ? [value] : []);handleSearch(selectedKeys, confirm)}}
        />
      </FilterWrapper>
    ),
  })),
  RangePicker: column => SearchFactory(column, CalendarOutlined, (handleSearch) => ({
    onFilter: (value, record) => {
      if(Array.isArray(column.dataIndex)){
        const recordValue = column.dataIndex.reduce((acc, key) => {
          return acc[key]
        }, record)
        return dayjs(recordValue).isAfter(value[0].startOf('day')) && dayjs(recordValue).isBefore(value[1].endOf('day'))
      }else{
        return dayjs(record[column.dataIndex]).isAfter(value[0].startOf('day')) && dayjs(record[column.dataIndex]).isBefore(value[1].endOf('day'))
      }
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <FilterWrapper>
        <DatePicker.RangePicker 
          format="DD/MM/YYYY"
          onChange={value => {setSelectedKeys(value ? [value] : []);handleSearch(selectedKeys, confirm)}}
        />
      </FilterWrapper>
    ),
  })),
}

export default ColumnSearch;