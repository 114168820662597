const empresa = (DataBase, Datatype) => {
    const Empresa = DataBase.new({
        nome: Datatype.STRING,
        id_municipio: Datatype.NUMBER,
        id_estado: Datatype.NUMBER,
        razao_social: Datatype.STRING,
        contato: Datatype.STRING,
        cnpj: Datatype.STRING,
        ie: Datatype.STRING,
        cep: Datatype.STRING,
        endereco: Datatype.STRING,
        fone: Datatype.STRING,
        fornecedor: Datatype.BOOLEAN,
        cliente: Datatype.BOOLEAN,
        transportador: Datatype.BOOLEAN,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'empresa',
        model: 'Empresa',
    });

    return Empresa;
}

export default empresa;