const agenda = (DataBase, Datatype) => {
    const Agenda = DataBase.new({
        id_marcador: Datatype.STRING,
        titulo: Datatype.STRING,
        data: Datatype.TIMESTAMP,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'agenda',
        model: 'Agenda',
    });

    Agenda.relate = (models) => [{
        model: models.Marcador,
        fk: "id_marcador",
        name: "marcador",
    }];

    return Agenda;
}

export default agenda;