const comissao = (DataBase, Datatype) => {
    const Comissao = DataBase.new({
        id_pedido: Datatype.STRING,
        id_pagador: Datatype.STRING,
        nr_duplicata: Datatype.NUMBER,
        valor: Datatype.NUMBER,
        data_vencimento: Datatype.TIMESTAMP,
        data_recebimento: Datatype.TIMESTAMP,
    }, {
        auth: true,
        dateLogs: true,
        softDelete: true,
        collection: 'comissao',
        model: 'Comissao',
    });

    Comissao.relate = (models) => [{
        model: models.Pedido,
        fk: "id_pedido",
        name: "pedido",
    }, {
        model: models.Empresa,
        fk: "id_pagador",
        name: "pagador",
    }];

    return Comissao;
}

export default comissao;