const FirebaseDatatypeEnum = {
    STRING: 0,
    NUMBER: 1,
    BOOLEAN: 2,
    MAP: 3,
    ARRAY: 4,
    NULL: 5,
    TIMESTAMP: 6,
    GEOPOINT: 7,
    CUSTOM: 8,
};

export default FirebaseDatatypeEnum;