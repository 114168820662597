const FirebaseConfig = {
  apiKey: "AIzaSyCjRNWq3EPUDp431yNqeLYXy2zeD4T7cHI",
  authDomain: "agrada-cms.firebaseapp.com",
  projectId: "agrada-cms",
  storageBucket: "agrada-cms.appspot.com",
  messagingSenderId: "798638046492",
  appId: "1:798638046492:web:96021299fb202a0950ced9",
  measurementId: "G-F9DXH7R68C"
};

export default FirebaseConfig