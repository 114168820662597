import dayjs from "dayjs";
import ColumnSearchUtil from "./ColumnSearchUtil";
import XmlReader from "xml-reader";

function formatCpf(cpf) {
	return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9)}`;
}

function formatCnpj(cnpj) {
	return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12)}`;
}

function parseXML(rawData) {
	if(!Array.isArray(rawData)){
		rawData = [rawData]
	}

	return rawData.reduce((obj, child) => {
		const value = child.children.length === 1 
			? child.children[0].children.length > 0 
				? parseXML(child.children[0])
				: child.children[0].value
			: parseXML(child.children)

		if(rawData.filter(vrf => vrf.name === child.name).length > 1){
			if(!obj[child.name]){
				obj[child.name] = []
			}

			obj[child.name].push(value);
		} else {
			obj[child.name] = value;
		}

		return obj
	}, {})
}

class Utils {

	/**
	 * Get first character from first & last sentences of a username
	 * @param {String} name - Username
	 * @return {String} 2 characters string
	 */
	static getNameInitial(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
	}

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo(navTree, path) {
		if( navTree.path === path ){
		  	return navTree;
		}
		let route; 
		for (let p in navTree) {
			if( navTree.hasOwnProperty(p) && typeof navTree[p] === 'object' ) {
				route = this.getRouteInfo(navTree[p], path);
				if(route){
					return route;
				}
			}
		}
		return route;
	}	

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex){
		if(!hex) {
			return 'dark'
		}
		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);
		function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
		function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
		function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}
		function cutHex(h) {return (h.charAt(0) === '#') ? h.substring(1,7):h}
		const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
		if (cBrightness > threshold){
			return 'dark'
		} else { 
			return 'light'
		}	
	}

	/**
	 * Darken or lighten a hex color 
	 * @param {String} color - Hex color code e.g '#3e82f7'
	 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
	 * @return {String} Darken or lighten color 
	 */
	static shadeColor(color, percent) {
		let R = parseInt(color.substring(1,3),16);
		let G = parseInt(color.substring(3,5),16);
		let B = parseInt(color.substring(5,7),16);
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
		R = (R<255)?R:255;  
		G = (G<255)?G:255;  
		B = (B<255)?B:255;  
		const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
		const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
		const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
		return `#${RR}${GG}${BB}`; 
	}

	/**
	 * Convert RGBA to HEX 
	 * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
	 * @return {String} HEX color 
	 */
	static rgbaToHex(rgba) {
		const trim = str => (str.replace(/^\s+|\s+$/gm,''))
		const inParts = rgba.substring(rgba.indexOf("(")).split(","),
			r = parseInt(trim(inParts[0].substring(1)), 10),
			g = parseInt(trim(inParts[1]), 10),
			b = parseInt(trim(inParts[2]), 10),
			a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
			const outParts = [
			r.toString(16),
			g.toString(16),
			b.toString(16),
			Math.round(a * 255).toString(16).substring(0, 2)
		];

		outParts.forEach(function (part, i) {
			if (part.length === 1) {
				outParts[i] = '0' + part;
			}
		})
		return (`#${outParts.join('')}`);
	}

	/**
	 * Returns either a positive or negative 
	 * @param {Number} number - number value
	 * @param {any} positive - value that return when positive
	 * @param {any} negative - value that return when negative
	 * @return {any} positive or negative value based on param
	 */
	static getSignNum(number, positive, negative) {
		if (number > 0) {
			return positive
		}
		if (number < 0) {
			return negative
		}
		return null
	}

	/**
	 * Returns either ascending or descending value
	 * @param {Object} a - antd Table sorter param a
	 * @param {Object} b - antd Table sorter param b
	 * @param {String} key - object key for compare
	 * @return {any} a value minus b value
	 */
	static antdTableSorter(a, b, key) {
		if(typeof a[key] === 'number' && typeof b[key] === 'number') {
			return a[key] - b[key]
		}

		if(typeof a[key] === 'string' && typeof b[key] === 'string') {
			a = a[key].toLowerCase();
			b = b[key].toLowerCase();
			return a > b ? -1 : b > a ? 1 : 0;
		}
		return
	}

	/**
	 * Filter array of object 
	 * @param {Array} list - array of objects that need to filter
	 * @param {String} key - object key target
	 * @param {any} value  - value that excluded from filter
	 * @return {Array} a value minus b value
	 */
	static filterArray(list, key, value) {
		let data = list
		if(list) {
			data = list.filter(item => item[key] === value)
		}
		return data
	}

	/**
	 * Remove object from array by value
	 * @param {Array} list - array of objects
	 * @param {String} key - object key target
	 * @param {any} value  - target value
	 * @return {Array} Array that removed target object
	 */
	static deleteArrayRow(list, key, value) {
		let data = list
		if(list) {
			data = list.filter(item => item[key] !== value)
		}
		return data
	}

	/**
	 * Wild card search on all property of the object
	 * @param {Number | String} input - any value to search
	 * @param {Array} list - array for search
	 * @return {Array} array of object contained keyword
	 */
	static wildCardSearch(list, input) {
		const searchText = (item) => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}
				if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
					return true;
				}
			}
		};
		list = list.filter(value => searchText(value));
		return list;
	}

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens) {
		let breakpoints = []
		for (const key in screens) {
			if (screens.hasOwnProperty(key)) {
				const element = screens[key];
				if (element) {
					breakpoints.push(key)
				}
			}
		}
		return breakpoints
	}

	/**
	 * Normalize String
	 * @param {String | Number} value
	 * @returns {String} string for code comparation
	 */
	static normalizeString = (value) => {
		return String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g,'').toLowerCase()
	}

	static installmentsValueGenerator = (qtd, totalValue, opts = {}) => {
		const rawValue = Number(Number(totalValue / qtd).toFixed(2));
		const rawSum = rawValue * qtd;

		if(rawSum === totalValue){
			return Array.from({ length: qtd }).map(_ => rawValue);
		} else {
			const rest = Number(Number(totalValue - rawSum).toFixed(2));

			if(opts.spread){
				let spreadQtd = 1;

				for(let i = 1; i <= qtd; i++){
					if(Number(Number((rest / i )).toFixed(2)) * i === rest){
						spreadQtd = i
					}
				}

				return [...Array.from({ length: qtd }).map((_, i)=> (i < spreadQtd) ? Number(Number(rawValue + (rest / spreadQtd)).toFixed(2)) : rawValue)]
			} else {
				if(rest < 0){
					return [...Array.from({ length: qtd - 1 }).map(_ => rawValue), Number(Number(rawValue + rest).toFixed(2))]
				}else{
					return [Number(Number(rawValue + rest).toFixed(2)), ...Array.from({ length: qtd - 1 }).map(_ => rawValue)]
				}
			}
		}
	}

	static readXML = (xml) => {
		return parseXML(XmlReader.parseSync(xml));
	}

	static readNFeXML = (xml) => {
		const { nfeProc } = this.readXML(xml);

		return {
			nr_nota: Number(nfeProc.NFe.infNFe.ide.nNF),
			valor_nota: Number(nfeProc.NFe.infNFe.cobr.fat.vLiq),
			data_nota: dayjs(nfeProc.NFe.infNFe.ide.dhSaiEnt).format(),
			peso_nota: Number(nfeProc.NFe.infNFe.transp.vol.pesoL),
			duplicatas: nfeProc.NFe.infNFe.cobr.dup.map(dup => ({
				dVenc: dayjs(dup.dVenc).format(),
				nDup: Number(dup.nDup),
				vDup: Number(dup.vDup),
			})),
		}
	}

	static compareStrings = (first, second) => {
		return this.normalizeString(first) === this.normalizeString(second)
	}

	static compareObjects = (first, second) => {
		let match = Object.keys(first).length === Object.keys(second).length;

		if(match){
			Object.keys(first).forEach(key => {
				if(first[key] !== second[key] && match){
					match = false
				}
			})
		}
		
		return match;
	}

	static searchString = (text, input) => {
		return this.normalizeString(text).indexOf(this.normalizeString(input)) >= 0
	}

	static formatCep = (cep) =>{
		if(!cep) return null;
		return `${cep.substring(0, 2)}.${cep.substring(2, 5)}-${cep.substring(5)}`;
	}

	static removePhoneFormatation = function (telefone) {
		if (!telefone) return ''
		return telefone
			.replace(/\(/g, '')
			.replace(/\)/g, '')
			.replace(/\./g, '')
			.replace(/-/g, '')
			.replace(/ /g, '')
			.replace(/ /g, '')
			.replace(/ /g, '')
			.replace(/\//g, '')
			.replace(/\+/g, '')
			.replace(/\(/g, '')
			.replace(/\)/g, '')
	}

	static formatPhoneNumber = function (telefone) {
		telefone = Utils.removePhoneFormatation(telefone)

		if (!telefone) return ''
		
		if (telefone.length === 8){
			return telefone
				.replace(/^(\d{4})(\d{4}).*/, "$1-$2")
		}
			
		if (telefone.length === 9){
			return telefone
				.replace(/^(\d{4})(\d{4}).*/, "$1-$2")
		}
			
		if (telefone.length === 10){
			return telefone
				.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
		}
		
		if (telefone.length === 11){
			return telefone
				.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3")
		}
		
		if (telefone.length === 12){
			return telefone
				.replace(/^(\d{2})(\d{2})(\d{4})(\d{4}).*/, "+$1 ($2) $3-$4")
		}
			
		if (telefone.length === 13){
			return telefone
				.replace(/^(\d{2})(\d{2})(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4")
		}
	}

	static validatePhoneNumber = function (telefone) {
		if(telefone.length >= 10) {
			return Promise.resolve();
		}else{
			return Promise.reject(new Error('Telefone inválido'));
		}
	}

	static formatCpfCpnj = (cpfCnpj) => {

		if(!cpfCnpj) return null;
	
		cpfCnpj = cpfCnpj
			.replace(".", "")
			.replace("-", "")
			.replace("/", "")
			.replace(" ", "");
	
		if(cpfCnpj.length === 11) {
			return formatCpf(cpfCnpj);
		}
	
		return formatCnpj(cpfCnpj);
	}

	static validateCpf = (cpf) => {
        let Soma;
        let Resto;
        Soma = 0;
      if (cpf === "00000000000")  return Promise.reject(new Error('CPF inválido'));
    
      for (let i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(cpf.substring(9, 10)) )  return Promise.reject(new Error('CPF inválido'));
    
      Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(cpf.substring(10, 11) ) )  return Promise.reject(new Error('CPF inválido'));
        return Promise.resolve();
	}

	static validateCnpj = (cnpj, callback) => {
		cnpj = cnpj.replace(/[^\d]+/g,'');
	
		if(cnpj === '')  return Promise.reject(new Error('CNPJ incompleto'));
			
		if (cnpj.length !== 14)
			return Promise.reject(new Error('CNPJ incompleto'));
		
		// Elimina CNPJs invalidos conhecidos
		if (cnpj === "00000000000000" || 
			cnpj === "11111111111111" || 
			cnpj === "22222222222222" || 
			cnpj === "33333333333333" || 
			cnpj === "44444444444444" || 
			cnpj === "55555555555555" || 
			cnpj === "66666666666666" || 
			cnpj === "77777777777777" || 
			cnpj === "88888888888888" || 
			cnpj === "99999999999999")
			return Promise.reject(new Error('CNPJ inválido'));
				
		// Valida DVs
		let tamanho = cnpj.length - 2
		let numeros = cnpj.substring(0,tamanho);
		let digitos = cnpj.substring(tamanho);
		let soma = 0;
		let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (String(resultado) !== String(digitos.charAt(0)))
			return Promise.reject(new Error('CNPJ inválido'));
				
		tamanho = tamanho + 1;
		numeros = cnpj.substring(0,tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (String(resultado) !== String(digitos.charAt(1)))
			return Promise.reject(new Error('CNPJ inválido'));
	
		if(callback) {
			callback(cnpj);
		}
		
		return Promise.resolve();
	}
	
	static validateCpfCnpj = (value, callback) => {
		if(value.length > 11) {
			value = value.replace(/[^\d]+/g,'');
	
			if(value === '')  return Promise.reject(new Error('CNPJ incompleto'));
				
			if (value.length !== 14)
				return Promise.reject(new Error('CNPJ incompleto'));
			
			// Elimina CNPJs invalidos conhecidos
			if (value === "00000000000000" || 
				value === "11111111111111" || 
				value === "22222222222222" || 
				value === "33333333333333" || 
				value === "44444444444444" || 
				value === "55555555555555" || 
				value === "66666666666666" || 
				value === "77777777777777" || 
				value === "88888888888888" || 
				value === "99999999999999")
				return Promise.reject(new Error('CNPJ inválido'));
					
			// Valida DVs
			let tamanho = value.length - 2
			let numeros = value.substring(0,tamanho);
			let digitos = value.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (String(resultado) !== String(digitos.charAt(0)))
				return Promise.reject(new Error('CNPJ inválido'));
					
			tamanho = tamanho + 1;
			numeros = value.substring(0,tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (String(resultado) !== String(digitos.charAt(1)))
				return Promise.reject(new Error('CNPJ inválido'));
	
			if(callback) {
				callback(value);
			}
			
			return Promise.resolve();
		}else{
			let Soma;
			let Resto;
			Soma = 0;
			if (value === "00000000000")  return Promise.reject(new Error('CPF inválido'));
			
			for (let i=1; i<=9; i++) Soma = Soma + parseInt(value.substring(i-1, i)) * (11 - i);
				Resto = (Soma * 10) % 11;
			
				if ((Resto === 10) || (Resto === 11))  Resto = 0;
				if (Resto !== parseInt(value.substring(9, 10)) )  return Promise.reject(new Error('CPF inválido'));
			
				Soma = 0;
				for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(value.substring(i-1, i)) * (12 - i);
				Resto = (Soma * 10) % 11;
			
				if ((Resto === 10) || (Resto === 11))  Resto = 0;
				if (Resto !== parseInt(value.substring(10, 11) ) )  return Promise.reject(new Error('CPF inválido'));
				return Promise.resolve();
		}
	}
}

Utils.ColumnSearch = ColumnSearchUtil;

export default Utils;